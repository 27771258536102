import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import useTranslations from '../components/useTranslations';
import PostItem from '../components/PostItem';
import SEO from '../components/seo';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

//import * as S from '../components/Content/styled';

const Post = (props) => {
  const post = props.data.post;
  const more = props.data.related.edges;

  const { seeMorePWA } = useTranslations();

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />
      <Grid>
        <div className="project">
          <Row>
            <Col md={4} sm={12} xs={12}>
              <div className="project__info">
                <h1 className="project__title">
                  {post.frontmatter.title}
                </h1>
                <div className="project__copy" dangerouslySetInnerHTML={{ __html: post.html }}></div>
              </div>
            </Col>
            <Col md={8} sm={12} xs={12}>
              {props.data.allFile.edges.map(({ node }, index) => (
                <div key={index} className="project__images">
                  <Img fluid={node.childImageSharp.fluid} />
                </div>
              ))}
            </Col>
          </Row>
          <div className="project__more project-list">
            <div className="project__more-title">
              {seeMorePWA}
            </div>
            <Row>
              {more.map(
                ({
                  node: {
                    frontmatter: {
                      title,
                      image,
                    },
                    fields: { slug }
                  },
                }) => (
                  <Col md={4} sm={12} xs={12}>
                    <div className="project-list__item">
                      <PostItem
                        slug={`/project/${slug}`}
                        title={title}
                        image={image}
                      />
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </Grid>
    </>
  );
};

export const query = graphql`
  query Post($locale: String!, $title: String!, $slug: String!, $category: String!) {
    post: markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        image
        category
      }
      html
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: $slug }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
    related: allMarkdownRemark(filter: {
      frontmatter: {
        category: {
          eq: $category
        }
        type: {
          eq: "project"
        }
      },
      fields: {
        slug: {
          ne: $slug
        }
      }
    }) {
      edges {
        node {
          frontmatter {
            title
            category
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Post;
